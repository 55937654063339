* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {

    background: #0F2027;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #2C5364, #203A43, #0F2027);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #2C5364, #203A43, #0F2027);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    color: #F6F6F6;
    font-family: 'Work Sans', sans-serif;
    font-size: 100%;
    margin: 0;
    padding: 0;
    height: 100%;

}

/*------------------------------------------------------HEADER------------------------------------------------------*/
#mode,
#logo {
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Centra l'icona verticalmente */
}

#mode {
    position: absolute;
    right: 0;
    margin-right: 20px;
    margin-top: -20px;


}


.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #000000;


}

.logo {
    width: 50px;
    /* Imposta la larghezza del logo */
    height: 50px;
    /* Imposta l'altezza del logo */
    margin-right: 20px;
    /* Aggiunge un margine destro per separare il logo dagli altri elementi della navbar */
}

.header-container {
    display: flex;
    /* Utilizza il layout flessibile per allineare correttamente il logo e gli altri elementi della navbar */
    align-items: center;
    /* Allinea verticalmente gli elementi della navbar */
}


.menu ul {
    list-style: none;
    display: flex;
    justify-content: center;
    /* Centra i testi orizzontalmente */
    align-items: center;
    /* Centra i testi verticalmente */
}

.menu ul li {
    margin-right: 24px;
    margin-top: 13px;
    margin-bottom: 13px;
    font-size: 1rem;

}

.menu ul li a {
    text-align: center;
    /* Centra il testo all'interno del suo contenitore */
    font-size: 1rem;

}

/*---------------------------------------------TITLE AREA------------------------------------------------------*/
.name {
    color: #FCA311;

}

.hero-image {
    width: 30%;
    border-radius: 50%;
}

#me {
    margin-top: 10%;
}

h1,
h2,
h3 {
    margin-bottom: 48px;
}

h1 {
    font-size: 58px;
    font-weight: 600px;
    line-height: 115px;
}

.name {
    margin-top: -25px;
}

h2 {
    font-size: 2rem;
    font-weight: 600px;
    text-transform: uppercase;
    color: #FCA311;
    margin-bottom: 0;

}


h3 {
    font-size: 38px;
    font-weight: normal;
    line-height: 56px;
}

#profession {
    margin-top: -25px;
}

.subtitle {
    font-size: 16px;
    font-weight: 600px;
    line-height: 28px;
    text-transform: uppercase;
    margin-bottom: 24px;
    margin-top: 15px;
    color: #FCA311;
}

.func {
    margin-top: -20px;

}

#title {
    margin-top: 100px;

}

a {

    text-decoration: none;
    color: #F6F6F6;
    text-transform: uppercase;

}

a:hover {
    color: #FCA311;
    ;
}

.link {
    font-size: 1rem;
    font-weight: 600px;
    line-height: 28px;
    text-decoration: none;
    color: #cbd1ff;
    text-transform: uppercase;
}

.link:hover {
    color: #FCA311;
}

.hero {

    height: 100vh;
    /*background-color: rgb(7,5,20);
    background: linear-gradient(0deg, rgba(7,5,20,1) 0%, rgba(15,25,93,1) 31%);
*/
}

.hero-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Centra verticalmente */
    text-align: center;
    /* Allinea il testo al centro */
    flex-direction: column;
    /* Allinea il contenuto al centro */
    margin-top: 100px;
}

.hero-cta {
    margin-top: 50px;
    /* Puoi regolare il margine superiore a tuo piacimento */
    text-align: center;
}

.hero-cta.button {
    display: block;
    margin: 0 auto;
    max-width: 200px;
    font-size: 16px;
    /* Riduci la dimensione del carattere */
    line-height: 24px;
    /* Imposta l'altezza della riga in base alla dimensione del carattere */
}

#button-area {
    margin-left: 10%;
}

.button {
    background-color: #F7F7F7;
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 12px 24px;
    border: none;
    border-radius: 36px;
    margin-right: 24px;
}

.button:hover {
    background-color: #FCA311;
    color: white;
}

/*-------------------------------------------------DATA AREA------------------------------------------------------*/

.box {
    padding: 24px;
    background-color: rgba(0, 1, 2, 0.414);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 24px;
    margin-bottom: 24px;
    margin-top: 100px;

}

.project {
    width: 80%;
    margin: 0 auto;
    border-radius: 25px;
    height: auto !important;
}

.name {
}

ul, p {
    margin-left:5%;
}

#name-area{
    margin-top:0;
}


.container ul {
    margin-left: 5%;
}

section {
    padding-top: 64px;
    padding-bottom: 64px;

}

#first {
    height: 30%;
}

#info {
    margin-top: -10%;

}

.works-menu {
    margin-top: 0;
}

.works-menu ul,
.me-menu ul {
    list-style-type: none;
    display: flex;
}

.works.menu ul li,
.me-menu ul li {
    margin-right: 24px;
}

.works {
    width: 90%;
    margin: 0 auto;
    margin-top: 100px;
    max-width: 1140px;


}

.aboutme {
    margin-top: -15%;

}

#interests {
    margin-top: 0;
}

#myskills {
    margin-top: 0;
}

#myexp {
    margin-top: 0;
}

.works-item {
    margin-bottom: 5%;
    border-radius: 25px;
    padding: 25px;
    background-color: #0F2027;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    height: auto;
}

.work-info {
    display: flex;
    flex-direction: column;
}

.work-info-label {
    width: 200px;
    flex-grow: 0;
    flex-shrink: 1;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 5px;
}

.work-info-content {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 0 auto;
}

.works-item-tags {
    font-size: 24px;
    color: #FCA311;
}

.lang_skill {
    border: 3px solid #3a7f67;
    border-radius: 50px;
    font-size: 1em;
    width: 15%;
    height: 100px;
    text-align: center;
    padding: 12px;
    margin-right: 24px;
    margin-top: 24px;
    display: inline-block;
    /* Imposta il display su "inline-block" per posizionarli uno accanto all'altro */
}

.exp {
    border: 3px solid #3a7f67;
    border-radius: 50px;
    font-size: 1em;
    width: 15%;
    height: 50px;
    text-align: center;
    padding: 12px;
    margin-right: 24px;
    margin-top: 24px;
    display: inline-block;
}

#exp_row {
    margin-top: -10%;
}

.exp-desc {
    margin-top: -15%;
}

.lang_skill:hover {
    background-color: #d8f3dc;
    color: #0B2D32;
}

.int_emoji {
    border: 3px solid #3a7f67;
    border-radius: 50px;
    font-size: 1em;
    width: 15%;
    height: 50px;
    text-align: center;
    padding: 12px;
    margin-right: 24px;
    margin-top: 24px;
    display: inline-block;
    /* Imposta il display su "inline-block" per posizionarli uno accanto all'altro */
}

.int_emoji:hover {
    background-color: #d8f3dc;
    color: #0B2D32;
}


.row {
    text-align: center;
}

.edu {
    margin-top: 150px;
    margin-bottom: 50px;
    width: 300px;
    height: 200px;
}

.edu:hover {
    background-color: #d8f3dc;
    color: #0B2D32;

}

.edu_years {
    margin-top: 10px;
}

#edu_row {
    margin-top: -100px;
}



/*-------------------------------------------------FOOTER------------------------------------------------------*/
footer {
    background-color: #203A43;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    text-align: center;
    padding: 20px;
    position: static;
    left: 0;
    bottom: 0;
    width: 100%;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.footer-logo {
    font-size: 20px;
}

.footer-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-menu ul li {
    display: inline-block;
    margin-right: 20px;
}

.footer-menu ul li:last-child {
    margin-right: 0;
}

.footer-menu ul li a {
    text-decoration: none;
}

.footer-menu ul li a:hover {
    text-decoration: underline;
}


.container {
    width: 80%;
    margin: 0 auto;
    border-radius: 25px;

}

.footer_info {
    display: inline-block;
    margin-right: 100px;
    margin-left: 100px;

}

.footer_link {
    font-size: 16px;
    font-weight: 600px;
    line-height: 28px;
    text-decoration: none;
    text-transform: uppercase;
    color: #F7F7F7;
}

.footer_link:hover {
    color: #FCA311;
}

#social {
    margin-top: 25px;
}

.social_icon {
    margin: 5px;
    display: inline-block;
    width: 2rem;

}

#copyright {
    margin-top: 25px;
    font-size: 0.6em;

}

#myedu {
    margin-top: -10%;
}



/*----------------------------------------------------------RESPONSIVE-----------------------------------------------------*/
/* Aggiunta di regole per i dispositivi di dimensioni inferiori a 768px */
@media screen and (max-width: 768px) {


    .logo {
        width: 100px;
        /* Imposta la larghezza del logo */
        height: 68px;
        /* Imposta l'altezza del logo */
    }

    .menu {
        display: none;
    }


    .header-container {
        position: relative;
    }

    .aboutme {
        margin-top: -50%;

    }

    .name {
        margin-top: 15px;
    }

    #me {
        width: 45%;
        margin-top: 15%;
    }

    #me_button {
        margin-top: 5%;
    }

    #infobutton {
        margin-top: 5%;
    }

    #first {
        height: 90%;

    }

    #info {
        margin-top: -75px;
    }

    .container {
        width: 90%;
    }

    .title {
        font-size: 68px;
        line-height: 70px;
        margin-top: 50px;

    }

    .link {
        font-size: 12px;
    }

    #skills {
        margin-top: 25px;
    }


    #edubox2 {
        margin-top: 0;
    }

    #edu_row {
        margin-top: 0;
    }

    .edu_info {
        margin-top: -35px;
        font-size: 1em;
    }

    .edu_years {
        font-size: 2em;

    }

    .lang_skill {
        width: 35%;
        margin: 20px;

    }

    .int_emoji {
        width: 35%;
        margin: 20px;
    }

    .edu {
        width: 80%;
        margin: 20px;
    }


    h3 {
        font-size: 18px;
        margin-top: -30px;
    }

    .header {
        font-size: 18px;
        padding: 10px;
    }

    .logo {
        background-size: 88px;
        padding-left: 40px;
        background-position: center;

    }

    .menu ul li {
        margin-right: 12px;
    }

    .hero h1 {
        font-size: 48px;
        line-height: 56px;
    }

    .hero-cta {
        margin-top: 24px;
    }

    .works {
        width: 90%;

    }

    .text {
        margin-left: -50px;
        text-align: justify;
    }

    .subtitle {
        margin-left: -50px;
    }



    .footer {
        padding: 10px;
        /* Ridimensiona il padding del footer per un aspetto più compatto */
        font-size: 12px;

        text-align: center;
    }


    .footer-logo {
        font-size: 16px;
        /* Ridimensiona il testo del logo del footer per adattarsi ai dispositivi più piccoli */
    }

    .footer-menu ul li {
        margin-right: 12px;
        /* Ridimensiona il margine destro degli elementi del menu del footer per adattarsi ai dispositivi più piccoli */
    }

    .transition-effect {
        transition: background-color 0.5s, color 0.5s;
    }

    .works-menu {
        visibility: hidden;
        display: none;
    }
}

@media screen and (max-width: 481px) {
    .works-item {
    }

    .project {
        width: 95%; 
        margin: 0 auto;
        height: auto; 
        
    }

    .project h2 {
        font-size: 1.5rem;
    }

    .project .work-info {
        font-size: 0.9rem;
    }

    .works-item-tags {
        font-size: 1rem;
    }
    
}

/*----------------------------TIMELINE------------------*/
.vertical-timeline-element-title {
    color: #000000;
    font-size: 1.5rem;
}

.vertical-timeline-element-subtitle {
    color: #000000;
}

#exp-points {
    color: #000000;
}

.timeline {
    margin-bottom: 15%;
}



/*--------------------------------SWIPER----------------------*/
.swiper-pagination-bullet {
    background-color: #000000; 
    width: 12px;
    height: 12px;
    opacity: 0.6;
    transition: opacity 0.3s;
}

.swiper-pagination-bullet-active {
    background-color: #FCA311; 
    opacity: 1;
}

.swiper-pagination-bullet:hover {
    opacity: 1;
}

.swiper-button-next,
.swiper-button-prev {
    background-color: transparent; 
    color: #FCA311;
    border-radius: 50%; 
    padding: 10px;
    font-size: 1rem;
}


