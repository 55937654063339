#navbar {
    position: fixed;
    flex-direction: row;
    top: 2%;
    left: 0;
    right: 0;
    width: auto;
    margin-left: 4.5%;
    margin-right: 4.5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    border-radius: 25px;
    z-index: 1000;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);

}

.link {
    text-decoration: none;

}

.btn-text {
    display: inline;
    margin-left: 15px;
}

#home {
    border-radius: 25px;
    margin: 0 20px;
    text-align: center;
    text-decoration: none;
}

#home-text {
    font-size: 1rem;
}


.option-menu {
    font-size: 1rem;
    height: auto;
    color: #F7F7F7;
    margin: 0 20px;
    cursor: pointer;
    border-radius: 25px;
    background-color: transparent;
    -webkit-backdrop-filter: blur(5px);
    border: none;
    text-decoration: none;
    width:10%;
    text-align: center;

}

a{
    text-decoration: none;
}
.option-menu:hover{
    color:#F7F7F7;
}
/*Mobile version*/
@media (max-width:481px) {
    #navbar {
        top: 88%;
        width: auto;
        left:0;
        right:0;
        justify-content: center;
        background-color: rgba(21, 64, 70, 0.509);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
    }

    .option-menu {
        width: 20%;
        font-size:1.5rem;
        padding: 10px;
        background-color: rgba(228, 228, 228, 0);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
    }
 
 


}